<template>
  <div class="login_box">
    <img class="login_img"
         src="@/assets/img/y1.png"
         alt="" />
    <div v-if="verify_account"
         class="login_box_right1">
      <div style="margin: 0 20px;">
        <div class="login_title">账号密码登录</div>
        <el-form :model="ruleForm1"
                 status-icon
                 :rules="rules1"
                 ref="ruleForm1"
                 class="demo-ruleForm">
          <el-form-item prop="mobile1"
                        class="login_form_item">
            <el-input prefix-icon="el-icon-user"
                      v-model.number="ruleForm1.mobile1"></el-input>
          </el-form-item>
          <el-form-item prop="password"
                        class="login_form_item">
            <el-input type="password"
                      prefix-icon="el-icon-lock"
                      v-model="ruleForm1.password"
                      autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div class="password_set">
          <el-checkbox v-model="rememberPassword">记住密码</el-checkbox>
          <div style="cursor: pointer"
               @click="handle_reset">重置密码</div>
        </div>
        <div class="sub_box">
          <button class="sub"
                  @click="handle_sub1">登录</button>
        </div>
        <div class="login_tab">
          <span @click="handle_tab">验证码登录</span>
        </div>
      </div>
    </div>
    <div v-if="!verify_account"
         class="login_box_right1">
      <div style="margin: 0 20px;">
        <div class="login_title">验证码登录</div>
        <el-form :model="ruleForm"
                 status-icon
                 :rules="rules"
                 ref="ruleForm"
                 class="demo-ruleForm">
          <el-form-item prop="mobile"
                        class="login_form_item">
            <el-input prefix-icon="el-icon-user"
                      v-model.number="ruleForm.mobile"></el-input>
          </el-form-item>
          <el-form-item prop="code"
                        class="login_form_item my_code">
            <el-input prefix-icon="el-icon-key"
                      suffix-icon=""
                      v-model="ruleForm.code"></el-input>
            <el-button class="get_code"
                       :disabled="disabled"
                       @click="handle_graph_code">{{
              valiBtn
            }}</el-button>
          </el-form-item>
        </el-form>
        <div class="password_set">
          <div style="cursor: pointer"
               @click="handle_reset">重置密码</div>
        </div>
        <div class="sub_box">
          <button class="sub"
                  @click="handle_sub">登录</button>
        </div>
        <div class="login_tab"
             @click="handle_tab">
          <span @click="handle_tab">账号密码登录</span>
        </div>
      </div>
    </div>
    <el-dialog :visible="dialogVisible2"
               class="my_dialog dialog_code graph_code_my_dialog"
               width="529px"
               :before-close="handleClose"
               append-to-body>
      <div class="graph_code_box">
        <img :src="graph_code" class="graph_code_img" @click="handle_refresh" />
        <el-input class="graph_code" size="mini" v-model="captcha">
            <el-button slot="append" @click="getCode">提交</el-button>
        </el-input>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import qs from 'qs'
export default {
  props: ['is_login'],
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (reg.test(value)) {
        return callback();
      }
      callback("手机号格式不正确");
    };
    var checkPhone1 = (rule, value, callback) => {
      const reg =
        /^0?(13[0-9]|15[012356789]|18[0-9]|14[578]|16[6]|17[035768]|19[19])[0-9]{8}$/;
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (reg.test(value)) {
        return callback();
      }
      callback("手机号格式不正确");
    };
    return {
      captcha: '',
      graph_code: '',
      dialogVisible2: false,
      rememberPassword: false,
      valiBtn: "获取验证码",
      disabled: false,
      verify_account: true,
      timer: null,
      ruleForm: {
        mobile: "",
        code: "",
      },
      rules: {
        mobile: [{ validator: checkPhone, required: true, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      ruleForm1: {
        password: "",
        mobile1: "",
      },
      rules1: {
        password: [{ validator: validatePass, trigger: "blur" }],
        mobile1: [{ validator: checkPhone1, required: true, trigger: "blur" }],
      },
    };
  },
  // beforeDestroy() {
  //   this.ruleForm = {
  //     mobile: "",
  //     code: "",
  //   };
  //   this.$refs["ruleForm"].resetFields();
  //   this.ruleForm1 = {
  //     mobile: "",
  //     password: "",
  //   };
  //   this.$refs["ruleForm1"].resetFields();
  // },
  mounted () {
    this.loadAccountInfo();
  },
  methods: {
    handleClose () {
      this.dialogVisible2 = false
    },
    handle_refresh () {
      this.graph_code = `/api/web/member/captcha?mobile=${this.ruleForm.mobile}&t=${Math.random()}`
    },
    handle_graph_code () {
      this.$refs["ruleForm"].validateField("mobile", (err) => {
        if (err) {
          return;
        } else {
          this.graph_code = `/api/web/member/captcha?mobile=${this.ruleForm.mobile}&t=${Math.random()}`
          this.dialogVisible2 = true
        }
      });
    },
    handle_reset () {
      this.$router.push({
        path: "/register",
        query: {
          type: "1",
        },
      });
      this.$emit("handle_close");
    },
    handle_tab () {
      if (this.$refs["ruleForm"]) {
        this.ruleForm = {
          mobile: "",
          code: "",
        };
        this.$refs["ruleForm"].resetFields();
      }
      if (this.$refs["ruleForm1"]) {
        this.ruleForm1 = {
          mobile: "",
          password: "",
        };
        this.$refs["ruleForm1"].resetFields();
      }
      this.verify_account = !this.verify_account;
    },
    getCode () {
      this.$axios({
        method: "POST",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        data: this.qs.stringify({
          mobile: this.ruleForm.mobile,
          captcha: this.captcha,
        }),
        url: "/api/web/member/send_verify_code",
      }).then((res) => {
        if(res.data.status == 200){
          this.$message({
            showClose: true,
            message: '验证码已发送，请注意查看',
            type: "success",
          });
          this.dialogVisible2 = false
          this.tackBtn(); //验证码倒数60秒
        }else{
          this.$message({
            showClose: true,
            message: res.data.errorMessage,
            type: "error",
          });
          this.handle_refresh();
        }
      });
    },
    tackBtn () {
      //验证码倒数60秒
      clearInterval(this.timer);
      let time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          this.valiBtn = "获取验证码";
          this.disabled = false;
        } else {
          console.log("2121221");
          this.disabled = true;
          this.valiBtn = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    handle_sub1 () {
      this.$refs["ruleForm1"].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            url: "/api/web/member/login",
            headers: {
              'Authorization': this.cookies.get('token'),
            },
            data: this.qs.stringify({
              mobile: this.ruleForm1.mobile1,
              password: this.ruleForm1.password,
            }),
          }).then((response) => {
            if (response.data.status == "200") {
              if (this.rememberPassword) {
                this.setCookie(
                  "accountInfo",
                  `${this.ruleForm1.mobile1}&${this.ruleForm1.password}`,
                  1440 * 3
                );
              } else {
                this.delCookie("accountInfo");
              }
              this.$message({
                message: "登录成功",
                type: "success",
              });
              this.cookies.set("token", response.data.data.token);
              this.$store.dispatch("setUser", response.data.data);
              this.$emit("handle_close");
            } else {
              this.$message({
                showClose: true,
                message: response.data.errorMessage === '账号已锁定' ? '您的账号已被停用，请联系客服' : response.data.errorMessage,
                type: "error",
              });
              this.delCookie("accountInfo");
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handle_sub () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            url: "/api/web/member/sms_login",
            headers: {
              'Authorization': this.cookies.get('token'),
            },
            data: this.qs.stringify({
              mobile: this.ruleForm.mobile,
              code: this.ruleForm.code,
            }),
          }).then((response) => {
            if (response.data.status == "200") {
              this.$message({
                message: "登录成功",
                type: "success",
              });
              this.$store.dispatch("setUser", response.data.data);
              this.cookies.set("token", response.data.data.token)
              this.$emit("handle_close");
            } else {
              this.$message({
                showClose: true,
                message: response.data.errorMessage === '账号已锁定' ? '您的账号已被停用，请联系客服' : response.data.errorMessage,
                type: "error",
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 设置cookie
    setCookie (c_name, value, expiremMinutes) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + expiremMinutes * 60 * 1000);
      document.cookie =
        c_name +
        "=" +
        escape(value) +
        (expiremMinutes == null ? "" : ";expires=" + exdate.toGMTString());
    },

    // 读取cookie
    getCookie (c_name) {
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1;
          var c_end = document.cookie.indexOf(";", c_start);
          if (c_end == -1) c_end = document.cookie.length;
          return unescape(document.cookie.substring(c_start, c_end));
        }
      }
      return "";
    },

    // 删除cookie
    delCookie (c_name) {
      var exp = new Date();
      exp.setTime(exp.getTime() - 1);
      var cval = this.getCookie(c_name);
      if (cval != null) {
        document.cookie = c_name + "=" + cval + ";expires=" + exp.toGMTString();
      }
    },
    loadAccountInfo () {
      //zhaopeng&A15hOsu8YeGnCsjb
      let accountInfo = this.getCookie("accountInfo");
      console.log(accountInfo.split("&"));
      //如果cookie里没有账号信息
      if (Boolean(accountInfo) == false) {
        return false;
      } else {
        //如果cookie里有账号信息
        let userName = "";
        let passWord = "";
        let accountInfo_list = accountInfo.split("&");

        userName = accountInfo_list[0];
        passWord = accountInfo_list[1];

        this.ruleForm1.mobile1 = userName;
        this.ruleForm1.password = passWord;
        this.rememberPassword = true;
      }
    },
  },
};
</script>
<style scoped>
.dialog_code{
  padding: 10px 0;
}
.graph_code{
  width: 45%;
}
.graph_code_img{
  height: 50px; 
}
.graph_code_box {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.get_code {
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c11d14 !important;
  cursor: pointer;
  border: none;
  padding: 12px 0 !important;
  background: rgba(0, 0, 0, 0) !important;
}
.get_code:focus,
.get_code:hover {
  background: rgba(0, 0, 0, 0) !important;
}
.login_tab {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.sub_box {
  width: 340px;
  margin: 44px auto 20px auto;
}
.sub {
  width: 340px;
  height: 50px;
  background: #c11d14;
  border: 1px solid #c11d14;
  border-radius: 3px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  cursor: pointer;
}
.password_set {
  width: 340px;
  margin: 25px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.login_form_item {
  width: 340px;
  margin: 25px auto;
  position: relative;
}
.login_title {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
  text-align: center;
  margin: 68px 0 61px 0;
}
.login_box {
  width: 780px;
  height: 500px;
  display: flex;
}
.login_img {
  width: 340px;
  height: 500px;
}
.login_box_right1 {
  width: 440px;
  height: 500px;
}
</style>
