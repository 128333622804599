import Vue from "vue";
import VueRouter from "vue-router";
const Online_open_account = () => import('../views/online_open_account')
const Complaint_advice = () => import('../views/complaint_advice')
const Commodity_details = () => import('../views/study_information/commodity_details.vue')
const Investment_research = () => import('../views/study_information/Investment_research.vue')
const Commodity_index = () => import('../views/study_information/commodity_index.vue')
const Hold_a_position = () => import('../views/study_information/hold_a_position.vue')
const Register = () => import('../views/login/register.vue')
const Intermediary = () => import('../views/article_list/intermediary')
const AppDownload = () => import('../views/app_download/index.vue')
const AppDownloadTip = () => import('../views/app_download/chatTip.vue')
const Details = () => import('../views/article_list/details.vue')
const Articlelist = () => import('../views/article_list/index.vue')
const Index = () => import('../views/home/index.vue')
const Business_handling = () => import('../views/second_level/business_handling/index.vue')
const Study_information = () => import('../views/second_level/study_information.vue')
const Walk_into_hengyin = () => import('../views/second_level/walk_into_hengyin/index.vue')

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/article_list",
    name: "Article_list",
    component: Articlelist,
  },
  {
    path: "/details",
    name: "Details",
    component: Details,
  },
  {
    path: "/app_download",
    name: "App_download",
    component: AppDownload,
  },
  {
    path: "/app_download_tip",
    name: "App_download_tip",
    component: AppDownloadTip,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/study_information/hold_a_position",
    name: "Hold_a_position",
    component: Hold_a_position,
  },
  {
    path: "/study_information/commodity_index",
    name: "Commodity_index",
    component: Commodity_index,
  },
  {
    path: "/study_information/commodity_details",
    name: "commodity_details",
    component: Commodity_details,
  },
  {
    path: "/study_information/Investment_research",
    name: "Investment_research",
    component: Investment_research,
  },
  {
    path: "/complaint_advice",
    name: "Complaint_advice",
    component: Complaint_advice,
  },
  {
    path: "/intermediary",
    name: "Intermediary",
    component: Intermediary,
  },
  {
    path: "/online_open_account",
    name: "Online_open_account",
    component: Online_open_account,
  },
  {
    path: "/second_level/business_handling",
    name: "Business_handling",
    component: Business_handling,
  },
  {
    path: "/second_level/walk_into_hengyin",
    name: "walk_into_hengyin",
    component: Walk_into_hengyin,
  },
  {
    path: "/second_level/study_information",
    name: "Study_information",
    component: Study_information,
  }

  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
