import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    setUser_mutations(state, name) {
      state.user = name;
    },
  },
  actions: {
    setUser({ commit, state }, name) {
      commit("setUser_mutations", name);
    },
  },
  modules: {},
  plugins: [createPersistedState()],
});
