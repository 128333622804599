<template>
  <div>
    <div class="login_twig">
      <div class="center_box">
        <div class="login_twig_left">
          您好，欢迎来到恒银期货有限公司！客服电话：400-0311-779
        </div>
        <div class="login_twig_right" v-if="!user">
          <span style="cursor: pointer" @click="handle_login">登录</span> |
          <span style="cursor: pointer" @click="handle_register">注册</span>
        </div>
        <div class="login_twig_right1" v-if="user">
          用户名：{{ user.mobile }}
          <span
            style="
              cursor: pointer;
              color: #c21d14;
              margin-left: 20px;
              display: inline-block;
              margin-left: 25px;
            "
            @click="handle_exit"
            >退出</span
          >
        </div>
      </div>
    </div>
    <div class="nav_box">
      <div class="center_box1">
        <img
          class="nav_box_title"
          src="../assets/img/title.png"
          @click="handle_index"
        />
        <i
          @click="drawer = true"
          style="font-size: 26px; color: #c11d14"
          class="el-icon-s-fold my_nav_tab"
        ></i>
        <div class="nav_box_text">
          <div class="nav_box_list1">
            <div
              :ref="'nav_box_item'"
              class="nav_box_item"
              @click="handle_index"
            >
              首页
            </div>
          </div>
          <div
            class="nav_box_list"
            v-for="(item, index) in nav_list"
            :key="item.id"
            @mouseover="mouseOver(item, index)"
            @mouseout="mouseOut(item, index)"
            @click="handle_nav_click(item)"
          >
            <div :ref="'nav_box_item' + index" class="nav_box_item">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="nav_select"
        @mouseover="mouseOver1(pageData)"
        @mouseout="mouseOut1(pageData)"
      >
        <div class="nav_select_center">
          <div class="nav_select_left">
            <div class="nav_select_left_title">
              {{ pageData ? pageData.name : "" }}
            </div>
            <img
              v-show="ide == 0"
              class="nav_select_left_img"
              src="../assets/img/nav1.jpg"
            />
            <img
              v-show="ide == 1"
              class="nav_select_left_img"
              src="../assets/img/nav4.jpg"
            />
            <img
              v-show="ide == 2"
              class="nav_select_left_img"
              src="../assets/img/nav3.jpg"
            />
            <img
              v-show="ide == 4"
              class="nav_select_left_img"
              src="../assets/img/nav2.jpg"
            />
            <div class="nav_select_left_text">
              恒银期货有限公司是河北省唯一 一家法人期货公司。
            </div>
          </div>
          <div class="nav_select_right">
            <div
              class="nav_select_right_2"
              v-for="(item, index) in nav2_list"
              :key="item.id"
            >
              <span
                @click="handle_skip(item)"
                class="nav_select_right_title2"
                >{{ item.name }}</span
              >
              <div class="nav_select_right_3">
                <span
                  @click="handle_skip(data)"
                  class="nav_select_right_title3"
                  v-for="data in item.children"
                  :key="data.id"
                >
                  {{ data.name }}
                </span>
              </div>
              <div v-if="index !== 3" class="xian"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <el-drawer
        size="50%"
        :withHeader="false"
        :visible.sync="drawer"
        :before-close="handleClose"
      >
        <el-menu
          :unique-opened="true"
          class="el-menu-vertical-demo"
          text-color="#000"
          active-text-color="#C21D14"
        >
          <el-menu-item index="1_1_1" @click="handle_index">
            首页
          </el-menu-item>
          <div v-for="item in nav_list" :key="item.id">
            <el-submenu :index="item.id + ''" v-if="item.children && item.children.length">
              <template slot="title">
                <div style="width: 100%; height: 100%; position: relative">
                  {{ item.name }}
                  <div
                    style="
                      width: 100%;
                      position: absolute;
                      left: 0;
                      top: 0;
                      height: 100%;
                    "
                    @click.stop="handle_secondary_pages(item)"
                  ></div>
                </div>
              </template>
              <div v-for="data in item.children" :key="data.id">
                <el-submenu :index="data.id + ''" v-if="item.children && data.children.length">
                  <template slot="title">
                    <span>
                      {{ data.name }}
                    </span>
                  </template>
                  <el-menu-item
                    v-for="ele in data.children"
                    :key="ele.id"
                    :index="ele.id + ''"
                    @click="handle_skip(ele)"
                    ><span class="dian1__"></span>{{ ele.name }}</el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                  :index="data.id + ''"
                  v-else
                  @click="handle_skip(data)"
                >
                  <span slot="title">{{ data.name }}</span>
                </el-menu-item>
              </div>
            </el-submenu>
            <el-menu-item
              :index="item.id + ''"
              v-else
              @click="handle_skip(item)"
            >
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </el-drawer>
    </div>
    <div class="login_dialog_my">
      <el-dialog
        :visible.sync="centerDialogVisible"
        width="780px"
        height="500px"
        style="border-radius: 6px"
        :destroy-on-close="true"
        center
      >
        <login @handle_close="handle_close" :is_login="true"></login>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import login from "../views/login/index.vue";
export default {
  components: {
    login,
  },
  data() {
    return {
      ide: 0,
      nav_list: [
        "首页",
        "走进恒银",
        "业务办理",
        "研究资讯",
        "投教基地",
        "联系我们",
      ],
      nav2_list: [],
      pageData: null,
      nav3_list: [],
      centerDialogVisible: false,
      drawer: false,
      // direction: 'ltr',
      // user:JSON.parse(sessionStorage.getItem('user'))
    };
  },
  computed: {
    user: function () {
      // console.log(this.$store.getters.getUser);
      // 通过vuex的getters方法来获取state里面的数据
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    handle_secondary_pages(data) {
      if (data.name === '业务办理' || data.name === '走进恒银' || data.name === '研究资讯') {
        this.$router.push({
          path: data.href,
        });
        this.drawer = false;
      }
    },
    handleClose(done) {
      done();
    },
    handle_exit() {
      this.cookies.remove('token', { path: '' });
      this.$store.dispatch("setUser", '');
    },
    handle_nav_click(data) {
      if (data.name != "投教基地") {
        this.$router.push({
          path: data.href,
          query: {
            column_id: data.columnId,
            id: data.id,
          },
        });
        this.$(".nav_select").hide();
      } else {
        window.open('http://edu.hyfutures.com')
      }
    },
    handle_close() {
      this.centerDialogVisible = false;
    },
    handle_register() {
      this.$router.push({
        path: "/register",
        query: {
          type: "0",
        },
      });
    },
    handle_login() {
      this.centerDialogVisible = true;
    },
    handle_index() {
      this.$router.push("/");
      this.$(".nav_select").hide();
      this.drawer = false;
    },
    handle_skip(data) {
      if (data.name !== '投教基地') {
        this.$router.push({
          path: data.href,
          query: {
            column_id: data.columnId,
            id: data.id,
          },
        });
        this.$(".nav_select").hide();
        this.drawer = false;
      } else {
        window.open('http://edu.hyfutures.com')
      }
    },
    mouseOut(data, index) {
      this.ide = index
      this.pageData = data;
      this.$(".nav_select").hide();
    },
    mouseOver(data, index) {
      this.ide = index
      if (data.name != "投教基地") {
        this.$(".nav_select").show();
      }
      this.pageData = data;
      let arr = [];
      data.children.forEach((item) => {
        arr.push({ ...item });
      });
      this.nav2_list = arr;
    },
    mouseOut1(data) {
      this.$(".nav_select").hide();
    },
    mouseOver1(data) {
      if (data.name != "投教基地") {
        this.$(".nav_select").show();
      }
    },
    getList() {
      this.$axios({
        method: "get",
        url: "/api/web/navigation/tree_list",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
      }).then((response) => {
        let arr = []
        response.data.forEach(item => {
          if (item.displayStatus !== '0') {
            arr.push(item)
          }
        })
        this.nav_list = arr;
      });
    },
  },
};
</script>
<style scoped>
.xian {
  width: 1px;
  height: 259px;
  background: #cdcdcd;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 55px;
}
.nav_select_right_title3 {
  text-decoration: none;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  display: inline-block;
  line-height: 35px;
  cursor: pointer;
  margin-right: 25px;
}
.nav_select_right_3 {
  margin: 0 0 0 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 220px;
}
.nav_select_right_title2 {
  margin: 53px 0 27px 28px;
  display: inline-block;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  border-left: #c32016 4px solid;
  padding-left: 7px;
  cursor: pointer;
}
.nav_select_right_2 {
  position: relative;
  width: 230px;
}
.nav_select_left_text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-top: 26px;
}
.nav_select_left_img {
  width: 239px;
  height: 130px;
}
.nav_select_left_title {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  margin-top: 50px;
  margin-bottom: 20px;
}
.nav_select_left {
  width: 239px;
  height: 352px;
}
.nav_select_right {
  background: #fff;
  width: 77%;
  height: 352px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.nav_select_center {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nav_select {
  width: 100%;
  overflow: hidden;
  height: 352px;
  display: none;
  /* height: 0px; */
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.35);
  position: absolute;
  z-index: 10;
  border-top: 1px solid #d6d6d6;
  background: linear-gradient(
    to right,
    #f1f1f1 0%,
    #f1f1f1 50%,
    white 51%,
    white 100%
  );
  /* margin-top: -2px; */
}
.nav_box_item:hover {
  border-bottom: #c32016 solid 3px;
  box-sizing: border-box;
}
/* .nav_box_item:hover .nav_select{
  display: none;
} */
.nav_box_item {
  cursor: pointer;
  height: 88px;
}
.nav_box_title {
  width: 178px;
  height: 44px;
  position: relative;
  top: 20px;
}
.nav_box_text {
  width: 745px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.center_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  line-height: 85px;
}
.center_box1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  line-height: 85px;
}
.login_twig_left {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}
.login_twig_right {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c21d14;
  height: 30px;
  line-height: 30px;
}
.login_twig_right1 {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  height: 30px;
  line-height: 30px;
}
.login_twig {
  height: 30px;
  background: #f9f9f9;
}
.nav_box {
  margin: 0 auto;
  height: 88px;
  background: #ffffff;
  position: relative;
}
</style>
