<template>
  <div id="nav">
    <div class="ie" :class="ie > 1 ? 'show' : ''">
      <p>为了您拥有更好的浏览体验</p>
      <p>建议使用 Chrome、Firefox浏览器</p>
      <span @click="close">X</span>
    </div>
    <suspended />
    <navtab />
    <router-view />
    <bottom />
  </div>
</template>
<script>
import bottom from "./views/bottom.vue";
import navtab from "./components/nav_tab.vue";
import suspended from "./components/suspended.vue";
export default {
  data() {
    return {
      ie: -1
    }
  },
  components: {
    bottom,
    navtab,
    suspended,
  },
  created() {
    this.ie = this.IEVersion();
  },
  methods: {
    close() {
      this.ie = -1;
    },
    IEVersion() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
      var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
      var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
      if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
          return 7;
        } else if (fIEVersion == 8) {
          return 8;
        } else if (fIEVersion == 9) {
          return 9;
        } else if (fIEVersion == 10) {
          return 10;
        } else {
          return 6; //IE版本<=7
        }
      } else if (isEdge) {
        return 'edge'; //edge
      } else if (isIE11) {
        return 11; //IE11  
      } else {
        return -1; //不是ie浏览器
      }
    }
  },
  watch: {
    '$route': function (to, from) {
      this.$parent.$el.scrollTop = 0
    }
  }
};
</script>
<style>
  /*网站黑白化*/
  /*html{
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,#grayscale");
    filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    -webkit-filter: grayscale(1);
  }*/
.ie {
  position: relative;
  width: 100%;
  background: #fdf2ab;
  height: 100px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 24px;
}
.ie > span {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
.show {
  display: flex;
}
.my_table .el-table {
  font-size: 16px !important;
}
.asasas .el-input__inner {
  border: none !important;
  height: 38px !important;
  background: #f5f5f5 !important;
}
.article_details td {
  border: 1px #ccc solid;
  padding: 10px;
}
.article_details table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 0px !important;
}
.width_1200 {
  width: 1200px;
  margin: 0 auto;
}
* {
  margin: 0;
  padding: 0;
  font-family: Microsoft YaHei !important;
}
button:hover {
  opacity: 0.8 !important;
}
.get_code {
  background: rgba(0, 0, 0, 0) !important;
}
.my_img {
  transition: all 0.6s;
}
.my_img:hover {
  transform: scale(1.1);
}
.el-range-editor.el-input__inner {
  display: inline-flex;
  align-items: center;
  padding: 3px 10px;
  height: 40px !important;
}
.el-backtop,
.el-calendar-table td.is-today {
  color: #000 !important;
}
.el-calendar-table td.is-selected {
  background-color: #fff !important;
}
.search_box .el-input--mini .el-input__inner {
  height: 40px !important;
  font-size: 16px;
}
.article_details img {
  max-width: 100%;
  height: auto;
}
.el-form-item__error {
  font-size: 16px !important;
}
.el-form-item__label {
  font-size: 16px !important;
}
.commodity_index_b .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px #ebeef5 dashed !important;
}
.commodity_index_b .el-table::before {
  height: 0px !important;
}
.el-dialog--center {
  border-radius: 6px !important;
}
.el-dialog__header {
  padding: 0 !important;
}
.el-dialog--center .el-dialog__body {
  padding: 0 !important;
}
.el-input__inner {
  height: 50px !important;
}
.my_code .el-input--prefix .el-input__inner {
  padding-right: 95px;
}
.my_code .el-input__suffix {
  display: none;
}
.el-menu--inline {
  background: #f9f9f9 !important;
  border-top: #e5e5e5 1px solid !important;
  border-bottom: #e5e5e5 1px solid !important;
}
.el-menu-item {
  background: rgba(0, 0, 0, 0) !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.btn-prev {
  border: 1px solid #e6e6e6 !important;
  border-radius: 2px 2px 2px 2px;
  background: #ffffff !important;
  padding: 0 13px !important;
  font-size: 16px !important;
}
.btn-next {
  border: 1px solid #e6e6e6 !important;
  border-radius: 2px 2px 2px 2px;
  background: #ffffff !important;
  padding: 0 13px !important;
  font-size: 16px !important;
}
.number {
  border: 1px solid #e6e6e6 !important;
  background: #ffffff !important;
  font-size: 16px !important;
  font-weight: normal !important;
}
.active {
  border: 1px solid #e6e6e6 !important;
  background: #e5e5e5 !important;
  color: #666 !important;
  font-size: 16px !important;
}
#nav {
  min-width: 1200px;
}
a {
  font-weight: bold;
  color: #2c3e50;
}
::-webkit-scrollbar-thumb {
  background: rgb(163, 163, 163);
}
::-webkit-scrollbar-track {
  background-color: #dfdfdf;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  display: inline-block;
}
.article_menu,
.my_nav_tab {
  display: none !important;
}
.ql-align-center {
  text-align: center;
}
.el-submenu .el-menu {
  z-index: 999 !important;
}

/* html{
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: url("data:image/svg+xml;utf8,#grayscale");
  filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1);
} */
</style>
