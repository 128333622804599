<template>
  <div class="bottom_">
    <div class="box_bottom">
      <div class="bottom_title">
        <img class="bottom_title_img1" style="width: 91px; height: 91px" src="@/assets/img/icon(3).png" />
        <img class="bottom_to bottom_title_img2" style="width: 183px; height: 38px; margin-left: 25px"
          src="@/assets/img/a6.png" />
      </div>
      <el-row>
        <el-col :span="15" class="blogroll">
          <p class="text_">
            友情链接：<a class="a_" v-for="item in list" :key="item.id" :href="item.link" target="view_window">{{ item.name
            }}&nbsp;&nbsp;&nbsp;&nbsp;</a>
          </p>
          <!--<p class="text_">本网站支持IPv6网络访问</p>-->
          <p class="text_">
            <span>本网站支持IPv6网络访问</span>
            <span style="vertical-align: middle; margin-left: 15px">
              <a :href="url1"
                 target="_blank" style="position: relative;display: inline-block;height: 38px;">
              <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div>
              <embed :src="url2" width="103" height="38"
                     type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/" />
            </a>
            </span>
          </p>
          <div style="display:flex;align-items:center;">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010402002055"
              style="font-weight:400;display:inline-block;text-decoration:none;"><img src="../assets/img/safe.png"
                style="float:left;" />
              <p style="font-size:14px;float:left;height:25px;line-height:25px;margin: 0px 0px 0px 5px; color:#c2c2c2;">
                冀公网安备13010402002055号</p>
            </a>&nbsp;
            <a target="_blank" href="https://beian.miit.gov.cn"
              style="font-weight:400;display:inline-block;text-decoration:none;">
              <p style="font-size:14px;float:left;height:25px;line-height:25px;margin: 0px 0px 0px 5px; color:#c2c2c2;">
                冀ICP备16019289号 </p>
            </a>&nbsp;
            <span
              style="font-weight:400;display:inline-block;text-decoration:none;height:25px;line-height:25px;font-size:14px;margin: 0px 0px 0px 5px; color:#c2c2c2;">
              版权所有©2017恒银期货有限公司版权所有
            </span>
          </div>
        </el-col>
        <el-col :lg="8" :xs="20" class="contact_way" style="margin-left: 33px">
          <p class="text_">联系电话：0311-87893486 0311-87893489</p>
          <p class="text_">报单电话：0311-67661567</p>
          <p class="text_">公司传真：0311-87036568</p>
          <p class="text_">电子邮箱：hyqhkf@qq.com</p>
          <div class="suspended_box1">
            <div class="suspended_box_item" @click="handle_service">
              <img style="width: 34px; height: 34px; margin-bottom: 10px" src="../assets/img/q2.png" />
            </div>
            <div class="suspended_box_item">
              <img style="width: 25px; height: 35px; margin-bottom: 10px" src="../assets/img/APP.png" alt="" />
              <div class="qrcode">
                <img style="width: 100%; height: 100%" :src="http + pageData.url1 + '?imageMogr2/thumbnail/!70p'" />
              </div>
            </div>
            <div class="suspended_box_item">
              <img style="width: 39px; height: 29px; margin-bottom: 10px" src="../assets/img/a9.png" alt="" />
              <div class="qrcode">
                <img style="width: 100%; height: 100%" :src="http + pageData.url2 + '?imageMogr2/thumbnail/!70p'" />
              </div>
            </div>
            <div class="suspended_box_item" @click="handle_app_download">
              <img style="width: 34px; height: 24px; margin-bottom: 10px" src="../assets/img/xz.png" />
            </div>
          </div>
          <div>
            <p class="text_">
              <span>本网站支持IPv6网络访问</span>
              <span style="vertical-align: middle; margin-left: 15px">
                <a :href="url1" target="_blank" style="position: relative;display: inline-block;height: 38px;">
                  <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div>
                  <embed :src="url2" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/" />
                </a>
              </span>
            </p>
          </div>
          <div class="text_ICP">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010402002055"
              style="display:inline-block;text-decoration:none;height:20px;line-height:20px;font-size:14px;"><img
                src="../assets/img/safe.png" style="float:left;" />
              <p
                style="font-weight:400;font-size:14px;height:20px;line-height:20px; color:#c2c2c2;float:left;margin: 0px 0px 0px 5px;">
                冀公网安备13010402002055号
              </p>
            </a>
            <a target="_blank" href="https://beian.miit.gov.cn"
              style="display:inline-block;text-decoration:none;height:20px;line-height:20px;font-size:14px;">
              <p
                style="font-weight:400;font-size:14px;height:20px;line-height:20px; color:#c2c2c2;float:left;margin: 0px 0px 0px 5px;">
                冀ICP备16019289号
              </p>
            </a>
            <span
              style="font-weight:400;display:inline-block;text-decoration:none;font-size:14px;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#c2c2c2;">
              版权所有©2017恒银期货有限公司版权所有
            </span>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      pageData: {},
      suspended_x: true,
      url1: undefined,
      url2: undefined
    };
  },
  mounted() {
    this.$axios({
      method: "get",
      url: "/api/web/qrcode/list",
      headers: {
        'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
      },
    }).then((response) => {
      this.pageData = {
        url1: response.data[0].url,
        url2: response.data[1].url,
      };
    });
    this.$axios({
      method: "get",
      url: `/api/web/friendly_link/list`,
      headers: {
        'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
      },
    }).then((res) => {
      this.list = res.data;
    });

    let hostname = window.location.hostname;
    // hostname = 'www.hbhyqh.com';
    if (hostname == 'www.hbhyqh.com') {
        this.url1 = "https://xyt.xcc.cn/getpcInfo?sn=1760233133371801600&language=CN&certType=8&url=" + hostname;
        this.url2 = "https://program.xinchacha.com/web/1760233133371801600=" + hostname + ".svg";
    } else {
        this.url1 = "https://xyt.xcc.cn/getpcInfo?sn=1760233242423705600&language=CN&certType=8&url=" + hostname;
        this.url2 = "https://program.xinchacha.com/web/1760233242423705600=" + hostname + ".svg";
    }
  },
  methods: {
    handle_app_download() {
      this.$router.push(`/app_download`)
    },
    handle_suspended_x() {
      this.suspended_x = false
    },
    handle_top() {
      this.$("html,body").animate({ scrollTop: 0 }, 1000); //回到顶端
    },
    handle_service() {
      window.open(
        `https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b978775569423459bba4fb4b260ff5c49ccdb3f7426157c6d793db96dd469c5c513eb3095653961cd9036da9bf1118309e9`
      );
    },
  }
};
</script>
<style scoped>
.box_bottom {
  width: 1200px;
  margin: 0 auto;
  position: relative;
}

.blogroll {
  border-right: 3px solid #4a4a4a;
}

.bottom_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  top: -43px;
}

.text_ {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c2c2c2;
  line-height: 28px;
}

.a_ {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c2c2c2;
  text-decoration: none;
}

.text_ICP {
  display: none;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c2c2c2;
  line-height: 28px;
}

.suspended_x {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
  cursor: pointer;
}

.qrcode {
  position: absolute;
  width: 80px;
  height: 80px;
  top: -95px;
  left: -27px;
  transform: rotateY(270deg);
  transition: all 0.2s ease-in-out;
}

.suspended_box_item:hover .qrcode {
  transform: translate(0px, 0px);
}

.suspended_box_top {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border: 1px solid #dddddd;
  opacity: 0.8;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 10px;
}

.suspended_box1 {
  display: none;
  justify-content: space-around;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 10px 0;
}

.suspended_box_item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.bottom_ {
  width: 100%;
  height: 251px;
  background: #222222;
  margin-top: 76px;
}
</style>
