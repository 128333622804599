<template>
  <div v-if="suspended_x"
       style="position: fixed; bottom: 50px; right: 20px; z-index: 998">
    <img class="suspended_x"
         @click="handle_suspended_x"
         src="../assets/img/a8.png">
    <div class="suspended_box">
      <div class="suspended_box_item"
           @click="handle_service">
        <img style="width: 34px; height: 34px; margin-bottom:10px;"
             src="../assets/img/q2.png" />
        <div>在线客服</div>
      </div>
      <div class="suspended_box_item">
        <img style="width: 25px; height: 35px; margin-bottom:10px;"
             src="../assets/img/APP.png"
             alt="" />
        <div>官方APP</div>
        <div class="qrcode">
          <img style="width: 100%; height: 100%"
               :src="http + pageData.url1+'?imageMogr2/thumbnail/!70p'" />
        </div>
      </div>
      <div class="suspended_box_item">
        <img style="width: 39px; height: 29px; margin-bottom:10px;"
             src="../assets/img/a9.png"
             alt="" />
        <div>微信公众号</div>
        <div class="qrcode">
          <img style="width: 100%; height: 100%"
               :src="http + pageData.url2+'?imageMogr2/thumbnail/!70p'" />
        </div>
      </div>
      <div class="suspended_box_item"
           @click="handle_app_download">
        <img style="width: 34px; height: 24px; margin-bottom:10px;"
             src="../assets/img/xz.png" />
        <div>软件下载</div>
      </div>
    </div>
    <div class="suspended_box_top">
      <div class="suspended_box_item"
           @click="handle_top">
        <img style="width: 39px; height: 29px"
             src="../assets/img/a2.png" />
        <div>回顶部</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pageData: {},
      suspended_x: true
    };
  },
  mounted () {
    this.$axios({
      method: "get",
      url: "/api/web/qrcode/list",
      headers: {
        'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
      },
    }).then((response) => {
      this.pageData = {
        url1: response.data[0].url,
        url2: response.data[1].url,
      };
    });
  },
  methods: {
    handle_app_download () {
      this.$router.push(`/app_download`)
    },
    handle_suspended_x () {
      this.suspended_x = false
    },
    handle_top () {
      this.$("html,body").animate({ scrollTop: 0 }, 1000); //回到顶端
    },
    handle_service () {
      window.open(
        `https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b978775569423459bba4fb4b260ff5c49ccdb3f7426157c6d793db96dd469c5c513eb3095653961cd9036da9bf1118309e9`
      );
    },
    // mouseOut (data) {
    //   this.$(".qrcode").hide()
    // },
    // mouseOver (data) {
    //   this.$(".qrcode").show();
    // },
  },
};
</script>
<style scoped>
.suspended_x {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;
  cursor: pointer;
}
.qrcode {
  position: absolute;
  width: 110px;
  height: 110px;
  top: -27px;
  left: -120px;
  transform: rotateY(270deg);
  transition: all 0.2s ease-in-out;
}
.suspended_box_item:hover .qrcode {
  transform: translate(0px, 0px);
}
.suspended_box_top {
  width: 100px;
  height: 100px;
  background: #ffffff;
  border: 1px solid #dddddd;
  opacity: 0.8;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 10px;
}
.suspended_box {
  width: 100px;
  height: 400px;
  background: #ffffff;
  border: 1px solid #dddddd;
  opacity: 0.85;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.suspended_box_item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}
</style>
